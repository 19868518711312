.mainContainer{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.contentWrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
}

.backgroundBlur{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backgroundBlur.blur{
    filter: blur(12px);
}

.noiseWrapper{
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Über dem Hintergrund, aber unter den Inhalten */
}
.noiseWrapper.blur{
    filter: blur(12px);
}

.underConstructionContainer{
    top: 33%;
    width: 80%;
    height: 20%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.underConstructionText{
    color: var(--darkBlack);
    font-size: 2rem;
}