:root{
    --lightPink: #f5c2e7;
    --darkBlack: #1b1b1b;
}

body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'basic';
    src: url('../assets/fonts/Basic-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'basis33';
    src: url('../assets/fonts/basis33.ttf') format('truetype');
}

@font-face {
    font-family: 'graph35pix';
    src: url('../assets/fonts/Graph-35-pix.ttf') format('truetype');
}

@font-face {
    font-family: 'highPixel7';
    src: url('../assets/fonts/high_pixel-7.ttf') format('truetype');
}

@font-face {
    font-family: 'ponde';
    src: url('../assets/fonts/ponde___.ttf') format('truetype');
}

@font-face {
    font-family: 'bdots';
    src: url('../assets/fonts/bpdots.squares-bold.otf') format('truetype');
}